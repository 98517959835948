// Global styles
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background: url("../images/body-bg-img-1.png");
  background-size: 100% auto;
  background-repeat: repeat-y;
  font-family: $primary-font-family;
  color: $primary-color;
}

h1,
h2,
h3,
button {
  font-family: $secondary-font-family !important;
}

a {
  text-decoration: none;
  color: $primary-color;
}

li {
  list-style-type: none;
}

button,
input,
textarea,
select {
  outline: none;
  border: none;
  background: none;
  font-family: $primary-font-family;
  color: $primary-color;
}

button,
select {
  cursor: pointer;
}

img {
  user-select: none;
}
