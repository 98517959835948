* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background: url("../images/body-bg-img-1.png");
  background-size: 100% auto;
  background-repeat: repeat-y;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
}

h1,
h2,
h3,
button {
  font-family: "GLD Display", sans-serif !important;
}

a {
  text-decoration: none;
  color: #ffffff;
}

li {
  list-style-type: none;
}

button,
input,
textarea,
select {
  outline: none;
  border: none;
  background: none;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
}

button,
select {
  cursor: pointer;
}

img {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.div-column-1 {
  float: left;
  width: 10%;
}

.div-column-2 {
  float: left;
  width: 90%;
}

/* Clear floats after the columns */
.div-row:after {
  content: "";
  display: table;
  clear: both;
}

.container {
  margin: auto;
  width: 85%;
  max-width: 1050px;
}

.navigation-bar {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: center;
}

.navigation-bar .container {
  max-width: 850px;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navigation-bar .container .logo-wrapper {
  display: flex;
}

.navigation-bar .container .logo-wrapper img {
  width: 80px;
}

.navigation-bar .container .hamburger-menu-open-trigger {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .navigation-bar .container .hamburger-menu-open-trigger {
    display: flex;
  }
}

.navigation-bar .container .navigation-bar-elements {
  display: flex;
}

@media only screen and (max-width: 1024px) {
  .navigation-bar .container .navigation-bar-elements {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
    transform: translateX(100%);
    width: 100%;
    height: 100%;
    background: url("../images/body-bg-img-1.png");
    background-size: 100% auto;
    background-repeat: repeat-y;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
  }

  .navigation-bar .container .navigation-bar-elements.active {
    transform: translateX(0%);
  }
}

.navigation-bar .container .navigation-bar-elements .hamburger-menu-close-trigger {
  position: absolute;
  top: 25px;
  right: 25px;
  display: none;
  transition: all 0.3s ease;
}

@media only screen and (max-width: 1024px) {
  .navigation-bar .container .navigation-bar-elements .hamburger-menu-close-trigger {
    display: flex;
  }
}

.navigation-bar .container .navigation-bar-elements .hamburger-menu-close-trigger:hover {
  opacity: 0.8;
}

.navigation-bar .container .navigation-bar-elements ul {
  margin-right: 15px;
  padding: 20px 35px;
  background: #000000;
  border-radius: 100px;
  display: flex;
}

@media only screen and (max-width: 1024px) {
  .navigation-bar .container .navigation-bar-elements ul {
    margin-right: 0px;
    padding: 0px;
    background: none;
    border-radius: 0px;
    flex-direction: column;
    align-items: center;
  }
}

.navigation-bar .container .navigation-bar-elements ul li {
  margin-right: 30px;
}

@media only screen and (max-width: 1024px) {
  .navigation-bar .container .navigation-bar-elements ul li {
    margin-bottom: 20px;
    margin-right: 0px;
  }
}

.navigation-bar .container .navigation-bar-elements ul li:last-child {
  margin-right: 0px;
}

.navigation-bar .container .navigation-bar-elements ul li a {
  display: flex;
  font-family: "GLD Display", sans-serif;
  font-size: 16px;
  transition: all 0.3s ease;
}

.navigation-bar .container .navigation-bar-elements ul li a:hover {
  transform: translateY(-2.5px);
}

.navigation-bar .container .navigation-bar-elements .cta-btn {
  width: 180px;
  height: 59px;
  border: 2px solid transparent;
  background: #ffffff;
  border-radius: 100px;
  font-size: 17px;
  color: #000000;
  transition: all 0.3s ease;
}

.navigation-bar .container .navigation-bar-elements .cta-btn:hover {
  border-color: #ffffff;
  background: transparent;
  color: #ffffff;
}

.footer {
  position: relative;
  width: 100%;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
}

.footer .footer-img-1 {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 200px;
}

.footer .footer-img-2 {
  position: absolute;
  z-index: 3;
  bottom: 0px;
  right: 100px;
  width: 300px;
}

@media only screen and (max-width: 1024px) {
  .footer .footer-img-2 {
    display: none;
  }
}

.footer .container {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer .container .logo-wrapper {
  margin-bottom: 25px;
  display: flex;
}

.footer .container .logo-wrapper img {
  width: 126px;
}

.footer .container .social-links {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.footer .container .social-links a {
  margin-right: 15px;
  display: flex;
  transition: all 0.3s ease;
}

.footer .container .social-links a:hover {
  transform: translateY(-2.5px);
}

.footer .container .social-links a:last-child {
  margin-right: 0px;
}

.footer .container p {
  font-size: 14px;
  font-weight: 500;
}

.landing-page {
  overflow-x: hidden;
}

.landing-page .hero {
  position: relative;
  width: 100%;
  padding: 320px 0px 320px 0px;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

@media only screen and (max-width: 1920px) {
  .landing-page .hero {
    padding: 220px 0px 320px 0px;
  }
}

@media only screen and (max-width: 1150px) {
  .landing-page .hero {
    padding: 220px 0px 150px 0px;
  }
}

.landing-page .hero .hero-img-1 {
  position: absolute;
  top: -320px;
  left: -120px;
  z-index: 2;
  width: 540px;
  pointer-events: none;
}

@media only screen and (max-width: 1150px) {
  .landing-page .hero .hero-img-1 {
    display: none;
  }
}

.landing-page .hero .hero-img-2 {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
  width: 540px;
  pointer-events: none;
}

@media only screen and (max-width: 1150px) {
  .landing-page .hero .hero-img-2 {
    display: none;
  }
}

.landing-page .hero .hero-img-3 {
  position: absolute;
  bottom: -320px;
  right: -120px;
  z-index: 2;
  width: 575px;
  pointer-events: none;
}

@media only screen and (max-width: 1150px) {
  .landing-page .hero .hero-img-3 {
    display: none;
  }
}

.landing-page .hero .hero-img-4 {
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 2;
  width: 540px;
  pointer-events: none;
}

@media only screen and (max-width: 1150px) {
  .landing-page .hero .hero-img-4 {
    display: none;
  }
}

.landing-page .hero .hero-img-6 {
  position: absolute;
  top: 176px;
  left: -69px;
  z-index: 2;
  width: 175.2px;
}

@media only screen and (max-width: 1150px) {
  .landing-page .hero .hero-img-6 {
    top: 270px;
    width: 150px;
    pointer-events: none;
  }
}

.landing-page .hero .hero-img-7 {
  position: absolute;
  top: -202px;
  right: 209px;
  z-index: 2;
  width: 260px;
}

@media only screen and (max-width: 1150px) {
  .landing-page .hero .hero-img-7 {
    top: -95px;
    right: 22%;
    width: 200px;
  }
}

@media only screen and (max-width: 678px) {
  .landing-page .hero .hero-img-7 {
    right: 0px;
  }
}

.landing-page .hero .hero-img-8 {
  position: absolute;
  bottom: -100px;
  right: -209px;
  z-index: 2;
  width: 160px;
}

@media only screen and (max-width: 1150px) {
  .landing-page .hero .hero-img-8 {
    bottom: 0px;
    right: -50px;
    width: 100px;
  }
}

.landing-page .hero .hero-img-9 {
  position: absolute;
  bottom: -200px;
  left: 350px;
  z-index: 2;
  width: 210px;
}

@media only screen and (max-width: 1150px) {
  .landing-page .hero .hero-img-9 {
    bottom: -150px;
    left: 30%;
    width: 160px;
  }
}

.landing-page .hero .container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-page .hero .container .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-page .hero .container .content .title {
  position: relative;
  margin-bottom: 25px;
}

.landing-page .hero .container .content .title h1 {
  font-size: 160px;
  text-align: center;
}

@media only screen and (max-width: 1150px) {
  .landing-page .hero .container .content .title h1 {
    font-size: 100px;
  }
}

@media only screen and (max-width: 678px) {
  .landing-page .hero .container .content .title h1 {
    font-size: 75px;
  }
}

.landing-page .hero .container .content .title h1 span {
  color: #000000;
}

.landing-page .hero .container .content .title img {
  position: absolute;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 260px;
}

@media only screen and (max-width: 1150px) {
  .landing-page .hero .container .content .title img {
    width: 168px;
  }
}

@media only screen and (max-width: 678px) {
  .landing-page .hero .container .content .title img {
    width: 127px;
  }
}

.landing-page .hero .container .content .countdown-box {
  margin-bottom: 35px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
}

.landing-page .hero .container .content .countdown-box .countdown-box-part {
  margin-right: 20px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-page .hero .container .content .countdown-box .countdown-box-part:last-child {
  margin-right: 0px;
}

.landing-page .hero .container .content .countdown-box .countdown-box-part .countdown-box-part-box {
  position: relative;
  margin-bottom: 10px;
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1150px) {
  .landing-page .hero .container .content .countdown-box .countdown-box-part .countdown-box-part-box {
    width: 50px;
    height: 50px;
  }
}

.landing-page .hero .container .content .countdown-box .countdown-box-part .countdown-box-part-box::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  width: 100%;
  height: 3px;
  background: rgba(255, 255, 255, 0.1);
}

.landing-page .hero .container .content .countdown-box .countdown-box-part .countdown-box-part-box h3 {
  position: relative;
  z-index: 2;
  font-size: 35px;
}

@media only screen and (max-width: 1150px) {
  .landing-page .hero .container .content .countdown-box .countdown-box-part .countdown-box-part-box h3 {
    font-size: 30px;
  }
}

.landing-page .hero .container .content .countdown-box .countdown-box-part p {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.landing-page .hero .container .content button {
  margin-bottom: 25px;
  width: 280px;
  height: 65px;
  border: 2px solid transparent;
  background: #ffffff;
  border-radius: 100px;
  font-size: 24px;
  color: #000000;
  transition: all 0.3s ease;
}

@media only screen and (max-width: 1150px) {
  .landing-page .hero .container .content button {
    width: 240px;
    height: 55px;
    font-size: 20px;
  }
}

.landing-page .hero .container .content button:hover {
  border-color: #ffffff;
  background: transparent;
  color: #ffffff;
}

.landing-page .hero .container .content .social-links {
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.landing-page .hero .container .content .social-links a {
  margin-right: 15px;
  display: flex;
  transition: all 0.3s ease;
}

.landing-page .hero .container .content .social-links a:hover {
  transform: translateY(-2.5px);
}

.landing-page .hero .container .content .social-links a:last-child {
  margin-right: 0px;
}

.landing-page .about {
  width: 100%;
  padding: 150px 0px 100px 0px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1150px) {
  .landing-page .about {
    padding: 0px 0px 100px 0px;
  }
}

.landing-page .about .container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-page .about .container .about-img-1 {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 95px;
  transform: rotate(-20deg);
}

@media only screen and (max-width: 1024px) {
  .landing-page .about .container .about-img-1 {
    display: none;
  }
}

.landing-page .about .container .about-img-2 {
  position: absolute;
  top: 120px;
  right: 0px;
  width: 95px;
  transform: rotate(20deg);
}

@media only screen and (max-width: 1024px) {
  .landing-page .about .container .about-img-2 {
    display: none;
  }
}

.landing-page .about .container .about-img-3 {
  position: absolute;
  bottom: -100px;
  left: 100px;
  z-index: 3;
  width: 97px;
  transform: rotate(20deg);
}

.landing-page .about .container h2 {
  margin-bottom: 50px;
  font-size: 120px;
  line-height: 90px;
  text-align: center;
}

@media only screen and (max-width: 1150px) {
  .landing-page .about .container h2 {
    margin-bottom: 35px;
    font-size: 80px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 678px) {
  .landing-page .about .container h2 {
    font-size: 60px;
    line-height: 50px;
  }
}

.landing-page .about .container h2 span {
  font-size: 80px;
  color: #000000;
}

@media only screen and (max-width: 1150px) {
  .landing-page .about .container h2 span {
    font-size: 60px;
  }
}

@media only screen and (max-width: 678px) {
  .landing-page .about .container h2 span {
    font-size: 40px;
  }
}

.landing-page .about .container p {
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
}

@media only screen and (max-width: 1150px) {
  .landing-page .about .container p {
    font-size: 17px;
    line-height: 37px;
  }

  .landing-page .about .container p br {
    display: none;
  }
}

.landing-page .nft {
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-page .nft.nft-2 {
  margin-top: 15px;
}

.landing-page .nft .slides {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
}

.landing-page .nft .slides .slide {
  display: flex;
  min-width: -moz-fit-content;
  min-width: fit-content;
  animation: slide 20s linear infinite;
}

@keyframes slide {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

.landing-page .nft .slides .slide img {
  margin-right: -30px;
  width: 244px;
  height: 240px;
  -o-object-fit: cover;
  object-fit: cover;
}

.landing-page .nft .slides .slide img:nth-child(2n) {
  margin-top: -20px;
  margin-right: -35px;
}

@media only screen and (max-width: 1024px) {
  .landing-page .nft .slides .slide img:nth-child(2n) {
    margin-right: -32px;
  }
}

@media only screen and (max-width: 1024px) {
  .landing-page .nft .slides .slide img {
    width: 180px;
    height: 176px;
    margin-right: -25px;
  }
}

.landing-page .mission {
  padding-top: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1150px) {
  .landing-page .mission {
    padding-top: 100px;
  }
}

.landing-page .mission .container {
  position: relative;
}

.landing-page .mission .container .mission-bg-img-1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 130%;
}

.landing-page .mission .container .mission-img-2 {
  position: absolute;
  top: -100px;
  right: -150px;
  z-index: 2;
  width: 320px;
}

@media only screen and (max-width: 1150px) {
  .landing-page .mission .container .mission-img-2 {
    right: -100px;
    width: 220px;
  }
}

.landing-page .mission .container .mission-img-3 {
  position: absolute;
  bottom: -75px;
  left: 0px;
  z-index: 2;
  width: 150px;
}

@media only screen and (max-width: 1150px) {
  .landing-page .mission .container .mission-img-3 {
    width: 115px;
  }
}

.landing-page .mission .container .content {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-page .mission .container .content h2 {
  margin-bottom: 50px;
  font-size: 120px;
  line-height: 90px;
  text-align: center;
  color: #000000;
}

@media only screen and (max-width: 1150px) {
  .landing-page .mission .container .content h2 {
    margin-bottom: 35px;
    font-size: 80px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 678px) {
  .landing-page .mission .container .content h2 {
    font-size: 60px;
    line-height: 50px;
  }
}

.landing-page .mission .container .content h2 span {
  font-size: 80px;
}

@media only screen and (max-width: 1150px) {
  .landing-page .mission .container .content h2 span {
    font-size: 60px;
  }
}

@media only screen and (max-width: 678px) {
  .landing-page .mission .container .content h2 span {
    font-size: 40px;
  }
}

.landing-page .mission .container .content p {
  max-width: 700px;
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  color: #000000;
  width: 65%;
}

@media only screen and (max-width: 1150px) {
  .landing-page .mission .container .content p {
    font-size: 17px;
    line-height: 37px;
  }

  .landing-page .mission .container .content p br {
    display: none;
  }
}

.landing-page .team {
  position: relative;
  padding-top: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1150px) {
  .landing-page .team {
    padding-top: 100px;
  }
}

.landing-page .team .team-img-7 {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 300px;
}

@media only screen and (max-width: 1150px) {
  .landing-page .team .team-img-7 {
    width: 160px;
  }
}

.landing-page .team .container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-page .team .container h2 {
  margin-bottom: 100px;
  font-size: 120px;
  line-height: 90px;
  text-align: center;
}

@media only screen and (max-width: 1150px) {
  .landing-page .team .container h2 {
    margin-bottom: 75px;
    font-size: 80px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 678px) {
  .landing-page .team .container h2 {
    font-size: 60px;
    line-height: 50px;
  }
}

.landing-page .team .container h2 span {
  font-size: 80px;
  color: #000000;
}

@media only screen and (max-width: 1150px) {
  .landing-page .team .container h2 span {
    font-size: 60px;
  }
}

@media only screen and (max-width: 678px) {
  .landing-page .team .container h2 span {
    font-size: 40px;
  }
}

.landing-page .team .container .boxes {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px;
}

@media only screen and (max-width: 1150px) {
  .landing-page .team .container .boxes {
    width: 600px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 750px) {
  .landing-page .team .container .boxes {
    width: 300px;
    grid-template-columns: 1fr;
  }
}

.landing-page .team .container .boxes .box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-page .team .container .boxes .box img {
  margin-bottom: 15px;
  width: 100%;
}

.landing-page .team .container .boxes .box h3 {
  margin-bottom: 10px;
  font-size: 38px;
}

@media only screen and (max-width: 1150px) {
  .landing-page .team .container .boxes .box h3 {
    font-size: 34px;
  }
}

.landing-page .team .container .boxes .box p {
  font-size: 17px;
  font-weight: 500;
}

@media only screen and (max-width: 1150px) {
  .landing-page .team .container .boxes .box p {
    font-size: 16px;
  }
}

.landing-page .roadmap {
  padding-top: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1150px) {
  .landing-page .roadmap {
    padding-top: 100px;
  }
}

.landing-page .roadmap .container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-page .roadmap .container h2 {
  margin-bottom: 100px;
  font-size: 120px;
  line-height: 90px;
  text-align: center;
}

@media only screen and (max-width: 1150px) {
  .landing-page .roadmap .container h2 {
    margin-bottom: 75px;
    font-size: 80px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 678px) {
  .landing-page .roadmap .container h2 {
    font-size: 60px;
    line-height: 50px;
  }
}

.landing-page .roadmap .container h2 span {
  font-size: 80px;
  color: #000000;
}

@media only screen and (max-width: 1150px) {
  .landing-page .roadmap .container h2 span {
    font-size: 60px;
  }
}

@media only screen and (max-width: 678px) {
  .landing-page .roadmap .container h2 span {
    font-size: 40px;
  }
}

.landing-page .roadmap .container .roadmap-img-2 {
  position: absolute;
  bottom: 0px;
  right: 80%;
  width: 600px;
}

@media only screen and (max-width: 1220px) {
  .landing-page .roadmap .container .roadmap-img-2 {
    display: none;
  }
}

.landing-page .roadmap .container .roadmap-img-3 {
  position: absolute;
  top: 0px;
  right: -240px;
  width: 380px;
}

@media only screen and (max-width: 1024px) {
  .landing-page .roadmap .container .roadmap-img-3 {
    display: none;
  }
}

.landing-page .roadmap .container .roadmap-content {
  padding-left: 280px;
  padding-bottom: 150px;
}

@media only screen and (max-width: 1220px) {
  .landing-page .roadmap .container .roadmap-content {
    padding-left: 0px;
  }
}

.landing-page .roadmap .container .roadmap-content .roadmap-phases {
  margin-bottom: 45px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

@media only screen and (max-width: 1220px) {
  .landing-page .roadmap .container .roadmap-content .roadmap-phases {
    width: 80vw;
    display: flex;
    overflow-x: scroll;
  }

  .landing-page .roadmap .container .roadmap-content .roadmap-phases::-webkit-scrollbar {
    display: none;
  }
}

.landing-page .roadmap .container .roadmap-content .roadmap-phases button {
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 1220px) {
  .landing-page .roadmap .container .roadmap-content .roadmap-phases button {
    width: 230px;
    min-width: 230px;
  }
}

.landing-page .roadmap .container .roadmap-content .roadmap-phases button img {
  width: 100%;
  opacity: 0;
  transition: all 0.3s ease;
}

.landing-page .roadmap .container .roadmap-content .roadmap-phases button.active img {
  opacity: 1;
}

.landing-page .roadmap .container .roadmap-content .roadmap-phases button span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 26px;
  opacity: all 0.3s ease;
}

.landing-page .roadmap .container .roadmap-content .roadmap-phases button.active span {
  color: #ffffff;
}

.landing-page .roadmap .container .roadmap-content p {
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
}

@media only screen and (max-width: 1220px) {
  .landing-page .roadmap .container .roadmap-content p {
    font-size: 17px;
    line-height: 37px;
  }
}

.landing-page .texts {
  overflow: hidden;
  margin-top: -100px;
  padding-top: 90px;
  padding-bottom: 100px;
}

.landing-page .texts .text {
  position: relative;
  z-index: 2;
  transform: rotate(3deg);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-page .texts .text .slides {
  width: -moz-fit-content;
  width: fit-content;
  background: #ffffff;
  display: flex;
}

.landing-page .texts .text .slides .slide {
  display: flex;
  min-width: -moz-fit-content;
  min-width: fit-content;
  animation: slide 20s linear infinite;
}

@keyframes slide {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

.landing-page .texts .text .slides .slide p {
  padding: 10px 30px;
  font-family: "GLD Display", sans-serif;
  font-size: 60px;
  color: #000000;
}

@media only screen and (max-width: 1150px) {
  .landing-page .texts .text .slides .slide p {
    font-size: 40px;
  }
}

.landing-page .texts .text-2 {
  position: relative;
  transform: rotate(-2.5deg);
  margin-top: -90px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 678px) {
  .landing-page .texts .text-2 {
    transform: rotate(-10deg);
  }
}

.landing-page .texts .text-2 .slides {
  width: -moz-fit-content;
  width: fit-content;
  background: #000000;
  display: flex;
}

.landing-page .texts .text-2 .slides .slide {
  display: flex;
  min-width: -moz-fit-content;
  min-width: fit-content;
  animation: slide 20s linear infinite;
}

@keyframes slide {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

.landing-page .texts .text-2 .slides .slide p {
  padding: 10px 30px;
  font-family: "GLD Display", sans-serif;
  font-size: 60px;
}

@media only screen and (max-width: 1150px) {
  .landing-page .texts .text-2 .slides .slide p {
    font-size: 40px;
  }
}

.mint-page {
  overflow-x: hidden;
}

.mint-page .nft {
  position: relative;
  z-index: 2;
  width: 100%;
  padding-top: 175px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mint-page .nft.nft-2 {
  padding-top: 0px;
  padding-bottom: 50px;
}

.mint-page .nft .slides {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
}

.mint-page .nft .slides .slide {
  display: flex;
  min-width: -moz-fit-content;
  min-width: fit-content;
  animation: slide 20s linear infinite;
}

@keyframes slide {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

.mint-page .nft .slides .slide img {
  margin-right: -30px;
  width: 244px;
  height: 240px;
  -o-object-fit: cover;
  object-fit: cover;
}

.mint-page .nft .slides .slide img:nth-child(2n) {
  margin-top: -20px;
  margin-right: -35px;
}

@media only screen and (max-width: 1024px) {
  .mint-page .nft .slides .slide img:nth-child(2n) {
    margin-right: -32px;
  }
}

@media only screen and (max-width: 1024px) {
  .mint-page .nft .slides .slide img {
    width: 180px;
    height: 176px;
    margin-right: -25px;
  }
}

.mint-page .mint {
  position: relative;
  width: 100%;
  padding: 50px 0px;
  display: flex;
  justify-content: center;
}

.mint-page .mint .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mint-page .mint .container .mint-box {
  width: 550px;
  padding: 25px;
  border: 7px solid #000000;
  background: #ffffff;
  border-radius: 45px;
}

@media only screen and (max-width: 678px) {
  .mint-page .mint .container .mint-box {
    width: 100%;
  }
}

.mint-page .mint .container .mint-box .mint-box-top {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 678px) {
  .mint-page .mint .container .mint-box .mint-box-top {
    flex-direction: column;
  }
}

.mint-page .mint .container .mint-box .mint-box-top .mint-box-top-left {
  width: 142px;
  display: flex;
}

@media only screen and (max-width: 678px) {
  .mint-page .mint .container .mint-box .mint-box-top .mint-box-top-left {
    margin-bottom: 20px;
  }
}

.mint-page .mint .container .mint-box .mint-box-top .mint-box-top-left img {
  width: 100%;
  border: 4px solid #000000;
  box-shadow: 4px 4px 0px #000000;
  border-radius: 30px;
}

.mint-page .mint .container .mint-box .mint-box-top .mint-box-top-right {
  flex-grow: 1;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px 25px;
  border: 4px solid #000000;
  border-left: none;
  border-radius: 0px 20px 20px 0px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 678px) {
  .mint-page .mint .container .mint-box .mint-box-top .mint-box-top-right {
    width: 100%;
    border-left: 4px solid #000000;
    border-radius: 20px;
    flex-direction: column;
    align-items: center;
  }
}

.mint-page .mint .container .mint-box .mint-box-top .mint-box-top-right .h3-wrapper {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
}

.mint-page .mint .container .mint-box .mint-box-top .mint-box-top-right .h3-wrapper h3 {
  font-size: 35px;
  line-height: 35px;
  text-transform: uppercase;
  color: #000000;
}

@media only screen and (max-width: 678px) {
  .mint-page .mint .container .mint-box .mint-box-top .mint-box-top-right .h3-wrapper h3 {
    text-align: center;
  }
}

.mint-page .mint .container .mint-box .mint-box-top .mint-box-top-right .h3-wrapper h3 span {
  font-size: 22px;
  font-weight: 900;
}

.mint-page .mint .container .mint-box .mint-box-top .mint-box-top-right .quantity-part {
  position: relative;
}

@media only screen and (max-width: 678px) {
  .mint-page .mint .container .mint-box .mint-box-top .mint-box-top-right .quantity-part {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.mint-page .mint .container .mint-box .mint-box-top .mint-box-top-right .quantity-part .btns {
  position: absolute;
  bottom: 0px;
  left: 45%;
  z-index: 10;
  display: flex;
}

@media only screen and (max-width: 678px) {
  .mint-page .mint .container .mint-box .mint-box-top .mint-box-top-right .quantity-part .btns {
    position: static;
    margin-top: 10px;
  }
}

.mint-page .mint .container .mint-box .mint-box-top .mint-box-top-right .quantity-part .btns button {
  display: flex;
  transition: all 0.3s ease;
}

.mint-page .mint .container .mint-box .mint-box-top .mint-box-top-right .quantity-part .btns button:hover {
  opacity: 0.8;
}

.mint-page .mint .container .mint-box .mint-box-top .mint-box-top-right .quantity-part .btns button:first-child {
  margin-right: 5px;
}

.mint-page .mint .container .mint-box .mint-box-top .mint-box-top-right .quantity-part .btns button img {
  width: 22px;
}

.mint-page .mint .container .mint-box .mint-box-top .mint-box-top-right .price-part h3 {
  text-align: right;
}

@media only screen and (max-width: 678px) {
  .mint-page .mint .container .mint-box .mint-box-top .mint-box-top-right .price-part h3 {
    text-align: center;
  }
}

.mint-page .mint .container .mint-box .cta-btn {
  width: 100%;
  height: 60px;
  border: 3px solid #000000;
  background: #000000;
  border-radius: 100px;
  font-family: "GLD Display", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: #ffffff;
  transition: all 0.3s ease;
}

@media only screen and (max-width: 1024px) {
  .mint-page .mint .container .mint-box .cta-btn {
    height: 55px;
  }
}

.mint-page .mint .container .mint-box .cta-btn:hover {
  background: transparent;
  color: #000000;
}

.mint-page .mint .container .mint-box .address {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 900;
  line-height: 25px;
  text-align: center;
  color: #000000;
}

@media only screen and (max-width: 1024px) {
  .mint-page .mint .container .mint-box .address {
    font-size: 18px;
    line-height: 28px;
  }
}

/*# sourceMappingURL=index.css.map */