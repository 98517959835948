// Footer component
.footer {
  position: relative;
  width: 100%;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;

  .footer-img-1 {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 200px;
  }

  .footer-img-2 {
    position: absolute;
    z-index: 3;
    bottom: 0px;
    right: 100px;
    width: 300px;

    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }

  .container {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo-wrapper {
      margin-bottom: 25px;
      display: flex;

      img {
        width: 126px;
      }
    }

    .social-links {
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      transition: all 0.3s ease;

      a {
        margin-right: 15px;
        display: flex;
        transition: all 0.3s ease;

        &:hover {
          transform: translateY(-2.5px);
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    p {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
