// Landing page
.landing-page {
  overflow-x: hidden;

  // Hero section
  .hero {
    position: relative;
    width: 100%;
    padding: 320px 0px 320px 0px;
    display: flex;
    justify-content: center;
    overflow: hidden;

    @media only screen and (max-width: 1920px) {
      padding: 220px 0px 320px 0px;
    }

    @media only screen and (max-width: 1150px) {
      padding: 220px 0px 150px 0px;
    }

    .hero-img-1 {
      position: absolute;
      top: -320px;
      left: -120px;
      z-index: 2;
      width: 540px;
      pointer-events: none;

      @media only screen and (max-width: 1150px) {
        display: none;
      }
    }

    .hero-img-2 {
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 2;
      width: 540px;
      pointer-events: none;

      @media only screen and (max-width: 1150px) {
        display: none;
      }
    }

    .hero-img-3 {
      position: absolute;
      bottom: -320px;
      right: -120px;
      z-index: 2;
      width: 575px;
      pointer-events: none;

      @media only screen and (max-width: 1150px) {
        display: none;
      }
    }

    .hero-img-4 {
      position: absolute;
      bottom: 0px;
      left: 0px;
      z-index: 2;
      width: 540px;
      pointer-events: none;

      @media only screen and (max-width: 1150px) {
        display: none;
      }
    }

    .hero-img-6 {
      position: absolute;
      top: 176px;
      left: -69px;
      z-index: 2;
      width: 175.2px;

      @media only screen and (max-width: 1150px) {
        top: 270px;
        width: 150px;
        pointer-events: none;
      }
    }

    .hero-img-7 {
      position: absolute;
      top: -202px;
      right: 209px;
      z-index: 2;
      width: 260px;

      @media only screen and (max-width: 1150px) {
        top: -95px;
        right: 22%;
        width: 200px;
      }

      @media only screen and (max-width: 678px) {
        right: 0px;
      }
    }

    .hero-img-8 {
      position: absolute;
      bottom: -100px;
      right: -209px;
      z-index: 2;
      width: 160px;

      @media only screen and (max-width: 1150px) {
        bottom: 0px;
        right: -50px;
        width: 100px;
      }
    }

    .hero-img-9 {
      position: absolute;
      bottom: -200px;
      left: 350px;
      z-index: 2;
      width: 210px;

      @media only screen and (max-width: 1150px) {
        bottom: -150px;
        left: 30%;
        width: 160px;
      }
    }

    .container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          position: relative;
          margin-bottom: 25px;

          h1 {
            font-size: 160px;
            text-align: center;

            @media only screen and (max-width: 1150px) {
              font-size: 100px;
            }

            @media only screen and (max-width: 678px) {
              font-size: 75px;
            }

            span {
              color: $secondary-color;
            }
          }

          img {
            position: absolute;
            top: 51%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 260px;

            @media only screen and (max-width: 1150px) {
              width: 168px;
            }

            @media only screen and (max-width: 678px) {
              width: 127px;
            }
          }
        }

        .countdown-box {
          margin-bottom: 35px;
          width: fit-content;
          display: flex;

          .countdown-box-part {
            margin-right: 20px;
            width: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;

            &:last-child {
              margin-right: 0px;
            }

            .countdown-box-part-box {
              position: relative;
              margin-bottom: 10px;
              width: 60px;
              height: 60px;
              background: rgba(255, 255, 255, 0.2);
              border-radius: 15px;
              display: flex;
              justify-content: center;
              align-items: center;

              @media only screen and (max-width: 1150px) {
                width: 50px;
                height: 50px;
              }

              &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0px;
                transform: translateY(-50%);
                width: 100%;
                height: 3px;
                background: rgba(255, 255, 255, 0.1);
              }

              h3 {
                position: relative;
                z-index: 2;
                font-size: 35px;

                @media only screen and (max-width: 1150px) {
                  font-size: 30px;
                }
              }
            }

            p {
              font-size: 14px;
              font-weight: 500;
              text-align: center;
            }
          }
        }

        button {
          margin-bottom: 25px;
          width: 280px;
          height: 65px;
          border: 2px solid transparent;
          background: $primary-color;
          border-radius: 100px;
          font-size: 24px;
          color: $secondary-color;
          transition: all 0.3s ease;

          @media only screen and (max-width: 1150px) {
            width: 240px;
            height: 55px;
            font-size: 20px;
          }

          &:hover {
            border-color: $primary-color;
            background: transparent;
            color: $primary-color;
          }
        }

        .social-links {
          display: flex;
          align-items: center;
          transition: all 0.3s ease;

          a {
            margin-right: 15px;
            display: flex;
            transition: all 0.3s ease;

            &:hover {
              transform: translateY(-2.5px);
            }

            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }

  // About section
  .about {
    width: 100%;
    padding: 150px 0px 100px 0px;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 1150px) {
      padding: 0px 0px 100px 0px;
    }

    .container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .about-img-1 {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 95px;
        transform: rotate(-20deg);

        @media only screen and (max-width: 1024px) {
          display: none;
        }
      }

      .about-img-2 {
        position: absolute;
        top: 120px;
        right: 0px;
        width: 95px;
        transform: rotate(20deg);

        @media only screen and (max-width: 1024px) {
          display: none;
        }
      }

      .about-img-3 {
        position: absolute;
        bottom: -100px;
        left: 100px;
        z-index: 3;
        width: 97px;
        transform: rotate(20deg);
      }

      h2 {
        margin-bottom: 50px;
        font-size: 120px;
        line-height: 90px;
        text-align: center;

        @media only screen and (max-width: 1150px) {
          margin-bottom: 35px;
          font-size: 80px;
          line-height: 70px;
        }

        @media only screen and (max-width: 678px) {
          font-size: 60px;
          line-height: 50px;
        }

        span {
          font-size: 80px;
          color: $secondary-color;

          @media only screen and (max-width: 1150px) {
            font-size: 60px;
          }

          @media only screen and (max-width: 678px) {
            font-size: 40px;
          }
        }
      }

      p {
        font-size: 20px;
        font-weight: 500;
        line-height: 40px;
        text-align: center;

        @media only screen and (max-width: 1150px) {
          font-size: 17px;
          line-height: 37px;

          br {
            display: none;
          }
        }
      }
    }
  }

  // NFT section
  .nft {
    position: relative;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.nft-2 {
      margin-top: 15px;
    }

    .slides {
      width: fit-content;
      display: flex;

      .slide {
        display: flex;
        min-width: fit-content;
        animation: slide 20s linear infinite;

        @keyframes slide {
          from {
            transform: translateX(0%);
          }

          to {
            transform: translateX(-100%);
          }
        }

        img {
          margin-right: -30px;
          width: 244px;
          height: 240px;
          object-fit: cover;

          &:nth-child(2n) {
            margin-top: -20px;
            margin-right: -35px;

            @media only screen and (max-width: 1024px) {
              margin-right: -32px;
            }
          }

          @media only screen and (max-width: 1024px) {
            width: 180px;
            height: 176px;
            margin-right: -25px;
          }
        }
      }
    }
  }

  // Mission section
  .mission {
    padding-top: 150px;
    width: 100%;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 1150px) {
      padding-top: 100px;
    }

    .container {
      position: relative;

      .mission-bg-img-1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 130%;
      }

      .mission-img-2 {
        position: absolute;
        top: -100px;
        right: -150px;
        z-index: 2;
        width: 320px;

        @media only screen and (max-width: 1150px) {
          right: -100px;
          width: 220px;
        }
      }

      .mission-img-3 {
        position: absolute;
        bottom: -75px;
        left: 0px;
        z-index: 2;
        width: 150px;

        @media only screen and (max-width: 1150px) {
          width: 115px;
        }
      }

      .content {
        position: relative;
        z-index: 3;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
          margin-bottom: 50px;
          font-size: 120px;
          line-height: 90px;
          text-align: center;
          color: $secondary-color;

          @media only screen and (max-width: 1150px) {
            margin-bottom: 35px;
            font-size: 80px;
            line-height: 70px;
          }

          @media only screen and (max-width: 678px) {
            font-size: 60px;
            line-height: 50px;
          }

          span {
            font-size: 80px;

            @media only screen and (max-width: 1150px) {
              font-size: 60px;
            }

            @media only screen and (max-width: 678px) {
              font-size: 40px;
            }
          }
        }

        p {
          max-width: 700px;
          font-size: 20px;
          font-weight: 500;
          line-height: 40px;
          text-align: center;
          color: $secondary-color;

          @media only screen and (max-width: 1150px) {
            font-size: 17px;
            line-height: 37px;

            br {
              display: none;
            }
          }
        }
      }
    }
  }

  // Team section
  .team {
    position: relative;
    padding-top: 150px;
    width: 100%;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 1150px) {
      padding-top: 100px;
    }

    .team-img-7 {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 300px;

      @media only screen and (max-width: 1150px) {
        width: 160px;
      }
    }

    .container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        margin-bottom: 100px;
        font-size: 120px;
        line-height: 90px;
        text-align: center;

        @media only screen and (max-width: 1150px) {
          margin-bottom: 75px;
          font-size: 80px;
          line-height: 70px;
        }

        @media only screen and (max-width: 678px) {
          font-size: 60px;
          line-height: 50px;
        }

        span {
          font-size: 80px;
          color: $secondary-color;

          @media only screen and (max-width: 1150px) {
            font-size: 60px;
          }

          @media only screen and (max-width: 678px) {
            font-size: 40px;
          }
        }
      }

      .boxes {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 60px;

        @media only screen and (max-width: 1150px) {
          width: 600px;
          grid-template-columns: repeat(2, 1fr);
        }

        @media only screen and (max-width: 750px) {
          width: 300px;
          grid-template-columns: 1fr;
        }

        .box {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            margin-bottom: 15px;
            width: 100%;
          }

          h3 {
            margin-bottom: 10px;
            font-size: 38px;

            @media only screen and (max-width: 1150px) {
              font-size: 34px;
            }
          }

          p {
            font-size: 17px;
            font-weight: 500;

            @media only screen and (max-width: 1150px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  // Roadmap section
  .roadmap {
    padding-top: 150px;
    width: 100%;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 1150px) {
      padding-top: 100px;
    }

    .container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        margin-bottom: 100px;
        font-size: 120px;
        line-height: 90px;
        text-align: center;

        @media only screen and (max-width: 1150px) {
          margin-bottom: 75px;
          font-size: 80px;
          line-height: 70px;
        }

        @media only screen and (max-width: 678px) {
          font-size: 60px;
          line-height: 50px;
        }

        span {
          font-size: 80px;
          color: $secondary-color;

          @media only screen and (max-width: 1150px) {
            font-size: 60px;
          }

          @media only screen and (max-width: 678px) {
            font-size: 40px;
          }
        }
      }

      .roadmap-img-2 {
        position: absolute;
        bottom: 0px;
        right: 80%;
        width: 600px;

        @media only screen and (max-width: 1220px) {
          display: none;
        }
      }

      .roadmap-img-3 {
        position: absolute;
        top: 0px;
        right: -240px;
        width: 380px;

        @media only screen and (max-width: 1024px) {
          display: none;
        }
      }

      .roadmap-content {
        padding-left: 280px;
        padding-bottom: 150px;

        @media only screen and (max-width: 1220px) {
          padding-left: 0px;
        }

        .roadmap-phases {
          margin-bottom: 45px;
          width: 100%;
          display: grid;
          grid-template-columns: repeat(4, 1fr);

          @media only screen and (max-width: 1220px) {
            width: 80vw;
            display: flex;
            overflow-x: scroll;

            &::-webkit-scrollbar {
              display: none;
            }
          }

          button {
            position: relative;
            width: 100%;

            @media only screen and (max-width: 1220px) {
              width: 230px;
              min-width: 230px;
            }

            img {
              width: 100%;
              opacity: 0;
              transition: all 0.3s ease;
            }

            &.active img {
              opacity: 1;
            }

            span {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 26px;
              opacity: all 0.3s ease;
            }

            &.active span {
              color: $secondary-color;
            }
          }
        }

        p {
          font-size: 20px;
          font-weight: 400;
          line-height: 40px;

          @media only screen and (max-width: 1220px) {
            font-size: 17px;
            line-height: 37px;
          }
        }
      }
    }
  }

  // Texts section
  .texts {
    overflow: hidden;
    margin-top: -100px;
    padding-top: 90px;
    padding-bottom: 100px;

    .text {
      position: relative;
      z-index: 2;
      transform: rotate(3deg);
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .slides {
        width: fit-content;
        background: $primary-color;
        display: flex;

        .slide {
          display: flex;
          min-width: fit-content;
          animation: slide 20s linear infinite;

          @keyframes slide {
            from {
              transform: translateX(0%);
            }

            to {
              transform: translateX(-100%);
            }
          }

          p {
            padding: 10px 30px;
            font-family: $secondary-font-family;
            font-size: 60px;
            color: $secondary-color;

            @media only screen and (max-width: 1150px) {
              font-size: 40px;
            }
          }
        }
      }
    }

    .text-2 {
      position: relative;
      transform: rotate(-2.5deg);
      margin-top: -90px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media only screen and (max-width: 678px) {
        transform: rotate(-10deg);
      }

      .slides {
        width: fit-content;
        background: $secondary-color;
        display: flex;

        .slide {
          display: flex;
          min-width: fit-content;
          animation: slide 20s linear infinite;

          @keyframes slide {
            from {
              transform: translateX(0%);
            }

            to {
              transform: translateX(-100%);
            }
          }

          p {
            padding: 10px 30px;
            font-family: $secondary-font-family;
            font-size: 60px;

            @media only screen and (max-width: 1150px) {
              font-size: 40px;
            }
          }
        }
      }
    }
  }
}