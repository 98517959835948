// Mint page
.mint-page {
  overflow-x: hidden;

  // NFT section
  .nft {
    position: relative;
    z-index: 2;
    width: 100%;
    padding-top: 175px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.nft-2 {
      padding-top: 0px;
      padding-bottom: 50px;
    }

    .slides {
      width: fit-content;
      display: flex;

      .slide {
        display: flex;
        min-width: fit-content;
        animation: slide 20s linear infinite;

        @keyframes slide {
          from {
            transform: translateX(0%);
          }
          to {
            transform: translateX(-100%);
          }
        }

        img {
          margin-right: -30px;
          width: 244px;
          height: 240px;
          object-fit: cover;

          &:nth-child(2n) {
            margin-top: -20px;
            margin-right: -35px;

            @media only screen and (max-width: 1024px) {
              margin-right: -32px;
            }
          }

          @media only screen and (max-width: 1024px) {
            width: 180px;
            height: 176px;
            margin-right: -25px;
          }
        }
      }
    }
  }

  // Mint section
  .mint {
    position: relative;
    width: 100%;
    padding: 50px 0px;
    display: flex;
    justify-content: center;

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .mint-box {
        width: 550px;
        padding: 25px;
        border: 7px solid $secondary-color;
        background: $primary-color;
        border-radius: 45px;

        @media only screen and (max-width: 678px) {
          width: 100%;
        }

        .mint-box-top {
          margin-bottom: 25px;
          display: flex;
          align-items: center;

          @media only screen and (max-width: 678px) {
            flex-direction: column;
          }

          .mint-box-top-left {
            width: 142px;
            display: flex;

            @media only screen and (max-width: 678px) {
              margin-bottom: 20px;
            }

            img {
              width: 100%;
              border: 4px solid #000000;
              box-shadow: 4px 4px 0px #000000;
              border-radius: 30px;
            }
          }

          .mint-box-top-right {
            flex-grow: 1;
            height: fit-content;
            padding: 10px 25px;
            border: 4px solid #000000;
            border-left: none;
            border-radius: 0px 20px 20px 0px;
            display: flex;
            justify-content: space-between;

            @media only screen and (max-width: 678px) {
              width: 100%;
              border-left: 4px solid #000000;
              border-radius: 20px;
              flex-direction: column;
              align-items: center;
            }

            .h3-wrapper {
              position: relative;
              width: fit-content;

              h3 {
                font-size: 35px;
                line-height: 35px;
                text-transform: uppercase;
                color: $secondary-color;

                @media only screen and (max-width: 678px) {
                  text-align: center;
                }

                span {
                  font-size: 22px;
                  font-weight: 900;
                }
              }
            }

            .quantity-part {
              position: relative;

              @media only screen and (max-width: 678px) {
                margin-bottom: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
              }

              .btns {
                position: absolute;
                bottom: 0px;
                left: 45%;
                z-index: 10;
                display: flex;

                @media only screen and (max-width: 678px) {
                  position: static;
                  margin-top: 10px;
                }

                button {
                  display: flex;
                  transition: all 0.3s ease;

                  &:hover {
                    opacity: 0.8;
                  }

                  &:first-child {
                    margin-right: 5px;
                  }

                  img {
                    width: 22px;
                  }
                }
              }
            }

            .price-part {
              h3 {
                text-align: right;

                @media only screen and (max-width: 678px) {
                  text-align: center;
                }
              }
            }
          }
        }

        .cta-btn {
          width: 100%;
          height: 60px;
          border: 3px solid #000000;
          background: #000000;
          border-radius: 100px;
          font-family: $secondary-font-family;
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
          color: #ffffff;
          transition: all 0.3s ease;

          @media only screen and (max-width: 1024px) {
            height: 55px;
          }

          &:hover {
            background: transparent;
            color: #000000;
          }
        }

        .address {
          margin-top: 10px;
          font-size: 15px;
          font-weight: 900;
          line-height: 25px;
          text-align: center;
          color: $secondary-color;

          @media only screen and (max-width: 1024px) {
            font-size: 18px;
            line-height: 28px;
          }
        }
      }
    }
  }
}
