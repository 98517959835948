// Navigation bar component
.navigation-bar {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: center;

  .container {
    max-width: 850px;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo-wrapper {
      display: flex;

      img {
        width: 80px;
      }
    }

    .hamburger-menu-open-trigger {
      display: none;

      @media only screen and (max-width: 1024px) {
        display: flex;
      }
    }

    .navigation-bar-elements {
      display: flex;

      @media only screen and (max-width: 1024px) {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 1000;
        transform: translateX(100%);
        width: 100%;
        height: 100%;
        background: url("../images/body-bg-img-1.png");
        background-size: 100% auto;
        background-repeat: repeat-y;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease;

        &.active {
          transform: translateX(0%);
        }
      }

      .hamburger-menu-close-trigger {
        position: absolute;
        top: 25px;
        right: 25px;
        display: none;
        transition: all 0.3s ease;

        @media only screen and (max-width: 1024px) {
          display: flex;
        }

        &:hover {
          opacity: 0.8;
        }
      }

      ul {
        margin-right: 15px;
        padding: 20px 35px;
        background: $secondary-color;
        border-radius: 100px;
        display: flex;

        @media only screen and (max-width: 1024px) {
          margin-right: 0px;
          padding: 0px;
          background: none;
          border-radius: 0px;
          flex-direction: column;
          align-items: center;
        }

        li {
          margin-right: 30px;

          @media only screen and (max-width: 1024px) {
            margin-bottom: 20px;
            margin-right: 0px;
          }

          &:last-child {
            margin-right: 0px;
          }

          a {
            display: flex;
            font-family: $secondary-font-family;
            font-size: 16px;
            transition: all 0.3s ease;

            &:hover {
              transform: translateY(-2.5px);
            }
          }
        }
      }

      .cta-btn {
        width: 180px;
        height: 59px;
        border: 2px solid transparent;
        background: $primary-color;
        border-radius: 100px;
        font-size: 17px;
        color: $secondary-color;
        transition: all 0.3s ease;

        &:hover {
          border-color: $primary-color;
          background: transparent;
          color: $primary-color;
        }
      }
    }
  }
}
